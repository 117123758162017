.login-container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 50px 10px;
    height: 100%;

    .form-options {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}