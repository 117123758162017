#side-bar {
    background-color: white;
    border-right: 1px solid whitesmoke;
    
    ul {
        border: none;
    }

    .logo-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .sidebar-logo {
            border-radius: 15%;
            height: 54px;
        }
    }
    .ant-layout-sider-trigger {
        background-color: white;
        color: black;
        border-right: 1px solid whitesmoke;
    }
}