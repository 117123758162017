@import '../../variables.scss';

.profile {
    position: relative;
    background-color: transparent;
    height: 100%;
    width: 100%;

    .profile-header {
        align-items: start;
        justify-content: start;
        background-color: transparent;
        padding: 0;
        height: fit-content;

        .title {
            display: block;
            font-weight: bold;
            text-align: center;
            width: 100%;
            height: 50px;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            box-shadow: 1px ;
            border-radius: 10px;
            padding: 5px;

            .tag {
                width: fit-content;
            }

            .message {
                line-height: normal;
                color: gray;
            }
        }
    }

    .profile-content {
        position: relative;
        height: 200px;
        padding: 30px 10px 0;
        display: flex;

        .user-form {
            display: flex;
            flex-direction: column;

            .form-data {
                flex: 1
            }

            .form-controls {
                position: sticky;
                bottom: 0;
            }
        }

    }
}