.history {
    position: relative;
    background-color: transparent;
    height: 100%;
    width: 100%;

    .history-header {
        align-items: start;
        justify-content: start;
        background-color: transparent;
        padding: 0;
        height: fit-content;

        .title {
            display: block;
            font-weight: bold;
            text-align: center;
            width: 100%;
        }
    }

    .history-content {
        position: relative;
        height: 200px;
        overflow-y: auto;
        padding: 30px 10px 0;

        .form-controls {
            position: sticky;
            bottom: 0;
        }
    }
}