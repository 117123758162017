.new-installation {
    position: relative;
    background-color: transparent;
    height: 100%;
    width: 100%;

    .new-installation-header {
        align-items: start;
        justify-content: start;
        background-color: transparent;
        padding: 0;
        height: fit-content;

        .title {
            display: block;
            font-weight: bold;
            text-align: center;
            width: 100%;
        }
    }

    .new-installation-content {
        position: relative;
        height: 100px;
        overflow-y: auto;
        padding: 30px 10px 0;

        .form-controls {
            position: sticky;
            bottom: 0;
        }
    }

    .new-installation-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        button {
            margin: 5px;
        }
    }

    .new-installation-info-container {
        position: absolute;
        background-color: rgba($color: white, $alpha: 0.7);
        height: 100%;
        width: 100%;
        border-radius: 10px;
    }
}