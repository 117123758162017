.connections {
    position: relative;
    background-color: transparent;
    height: 100%;
    width: 100%;

    .connections-header {
        align-items: center;
        justify-content: center;
        column-gap: 5px;
        background-color: transparent;
        padding: 0;
        height: fit-content;
        display: flex;
        flex-wrap: wrap;

        .title {
            font-weight: bold;
            text-align: center;
            line-height: 25px;
        }

        .subtitle {
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            box-shadow: 1px;
            border-radius: 10px;
            padding: 5px;

            .tag {
                width: fit-content;
            }

            .message {
                line-height: normal;
                color: gray;
                line-height: 15px;
            }
        }
    }

    .connections-content {
        position: relative;
        height: 100%;
        padding: 10px 10px 0 0;

        .form-controls {
            position: sticky;
            bottom: 0;
        }
    }
}