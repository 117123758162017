body {
	margin: 0;
	font-family: "Source Sans Pro", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;
	width: 100vw;
}
#root {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media only screen and (max-width: 576px) {
	.ant-pagination {
		.ant-pagination-options {
			display: block;
		}
	}
}
