@import "./variables.scss";

.app {
  background-color: $background-color;
  height: 100%;

  .home-layout {
    background-color: white;
    height: 100%;

    .navigation-layout {
      background-color: inherit;
    }
  }
}