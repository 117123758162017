.access-manager {
    position: relative;
    background-color: transparent;
    height: 100%;
    width: 100%;

    .access-manager-header {
        align-items: start;
        justify-content: start;
        background-color: transparent;
        padding: 0;
        height: fit-content;

        .title {
            display: flex;
            font-weight: bold;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            width: 100%;
        }
    }

    .access-manager-content {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .create-button {
            align-self: end;
        }
    }
}