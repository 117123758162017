.client-transfer-form {
    max-height: 400px;
    overflow-y: auto;
    padding: 0 10px 10px 0;

    .plan-fields {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        column-gap: 5px;

        .plan-select-field {
            width: 70%;
        }
    }

    .select-form-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 5px;
    }
}