@import '../../variables.scss';

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 30px;
    background-color: rgba($color: black, $alpha: 0.5);
    color: $primary;
    z-index: 3;
}