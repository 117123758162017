.payments {
    height: 100%;
    display: flex;
    flex-direction: column;

    .picker-options {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
    }

    .table-container {
        height: 75%;
        flex: 1;

        .payments-table {
            margin: 10px 0;

            .details-button {
                padding: 0;
                color: blue;

                &:hover {
                    color: blue !important;
                    background: transparent !important;
                }
            }
        }
    }

    .table-search-title {
        display: flex;
        justify-content: space-between;
    }

    .empty-payments {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        row-gap: 10px;
        border: 1px dashed lightgray;
        height: 90%;
        margin: 5px;
        color: gray;
    }

    .ant-popover {
        .payments-popover-content {
            align-items: flex-start;
            justify-content: center;
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            width: 260px;

            .popover-field {
                display: flex;
                column-gap: 5px;
                border-bottom: 1px solid lightgray;
                width: 100%;

                span:first-of-type {
                    font-weight: 500;
                }
            }

            .popover-field:last-of-type {
                border-bottom: none;
            }
        }
    }

    .bottom-options {
        display: flex;
        justify-content: space-between;
        padding: 5px 0 10px;

        .unpaid-totals {
            display: flex;
            flex-direction: column;

            .total-users,
            .total-currency {
                display: flex;
                column-gap: 5px;

                :first-child {
                    font-weight: 500;
                }
            }
        }
    }
}