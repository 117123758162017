.roles-form {
    .field-title {
        font-weight: bold;
    }

    .checkbox-container{
        display: flex;
        flex-direction: row;
        column-gap: 5px;

        .checkbox-group {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            border: 1px dashed #d3d3d3a4;
            border-radius: 10px;
            padding: 5px;

            .category-title {
                font-weight: bold;
                border-bottom: 1px solid rgba(245, 245, 245, 0.753);
            }

            .checkbox-options {
                justify-content: space-between;
            }
        }
    }
}