@import "../../variables.scss";

.app-header {
    align-items: center;
    background-color: $background-color;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px;
    height: fit-content;
}