.report-page {
    width: 100%;
    height: 100%;
    

    .picker-options {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        padding-bottom: 10px;

        .date-picker {
            display: flex;
            flex-direction: column;
        }
    }

    .tiles-container {
        width: 100%;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 10px;
        row-gap: 10px;
        margin-bottom: 150px;
    }
}